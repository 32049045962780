<template>
<div>
  <b-col class="mt-2">
    <b-form-group>
    <template #label><small>{{ $t(`agent.list.${i18nKey}.register_1.title`) }}</small>
    <span class="float-right mx-25 text-secondary"><small>{{ $t(`agent.list.${i18nKey}.register_1.description`) }}</small></span>
    <helper-tooltip tooltipPlacement="right" size="12" innerText="eopae" innter/></template>
        <!--  -->
        <b-form-select
          size="md"
          class="w-100"
          v-model="agent.register_1.value"
        >
          <b-form-select-option v-for="item in select_options" :key="item.id" :value="item.value">
            {{item.text}}
          </b-form-select-option>
        </b-form-select>
    </b-form-group>
  </b-col>

  <b-col md="12" >
    <p class="text-center text-secondary m-0"><small>token</small></p>
    <div class="d-flex justify-content-center mt-0"> 
      <b-icon icon="key" scale="2" variant="success" class="mt-25"></b-icon>
    </div>
  </b-col>
  <b-col md="12" class="d-flex justify-content-center mt-1">
    <b-icon icon="arrow-down-short" scale="2"></b-icon>
  </b-col>

  <b-col md="12" class="my-2">
      <custom-input
        v-if="variables"
        class="my-1"
        id="custom-input-rd"
        :possibleValues="variables"
        v-model="agent.register_destiny"
        advanced
      >
        <template #label>
          {{ $t(`agent.list.${i18nKey}.register_destiny.title`) }}
          <helper-tooltip tooltipPlacement="right" size="12" innerText="eopae" innter />
          <span class="float-right text-right mx-25 text-secondary">{{ $t(`agent.list.${i18nKey}.register_destiny.description`) }}</span>
        </template>
      </custom-input>
        <div v-else class="mb-2">
          <b-skeleton class="mb-25" width="25%" height="12px" />
          <b-skeleton class="mb-25" width="100%" height="37px" />
          <b-skeleton class="mb-25" width="25%" height="14px" />
        </div>
  </b-col>

</div>
</template>

<script>
import { BLink, BSkeleton, BFormCheckbox, BButton, BContainer, BCol, BRow, BSidebar, BIcon, BFormSelect, BFormSelectOption, BFormGroup } from "bootstrap-vue";
import { mapActions } from "vuex";
import { v4 as uuidv4 } from "uuid";
import DefaultAgent from "./../DefaultAgent"
import VariablesPanel from "@/views/pages/middleware/MiddlewareManagement/Components/VariablesPanel.vue";
import CustomInput from "@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue";
import NormalizedAgent from "@/custom/class/NormalizedAgent.js";
import HelperTooltip from '@/layouts/components/HelperTooltip';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import CaseParser from "@/custom/class/Enum/CaseParser.js"

import AgentsMixin from '../AgentsMixin'

export default {
  mixins: [AgentsMixin],

  components: {
    BButton,
    BContainer,
    BCol,
    BRow,
    BSidebar,
    VariablesPanel,
    CustomInput,
    BFormCheckbox,
    BIcon,
    BSkeleton,
    HelperTooltip,
    BLink,
    VuePerfectScrollbar,
    BFormSelect,
    BFormSelectOption,
    BFormGroup
    
  },
  props: {
    middlewareID: {
      type: Number,
      required: true,
    },
    agentID: {
      type: Number,
      default: undefined,
    },
    value: {
      type: NormalizedAgent,
      default: undefined
    },
    uuid: {
      type: String,
      default: uuidv4(),
    },
    width: {
      type: String,
      default: "40%"
    },
    agentBlockID: {
      type: [ Number, String ],
      default: 1,
    },
    variables:{
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      actionID: 25,
      i18nKey: 'third_party_token',
      defaultAgent: {
        id: null,
        enum_agent_action_id: this.actionID,
        agent_block_id: this.agentBlockID,
        register_1: {
          source: 7,
          value: ""
        },
        register_2: {
          source: 7,
          value: ""
        },
        register_destiny: {
          source: 10,
          value: ""
        },
        execution_order: 1,
        fatal_on_fail: true,
      },

      uuidMap: {},

      variant_map: null,

      isSaving: false,
      select_options: [
        {value: 1, text: "Google"},
        {value: 2, text: "Facebook"},
      ]
    };
  },
  computed: {
    agent: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    toSaveRegs() {
      return {
        register_1: true,
        register_2: false,
        register_destiny: true,
      }
    },
    caseParserOptions(){
      return new CaseParser().items;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(["insertAgent", "updateAgent", "getAgentByID"]),
    init() {
      DefaultAgent.fetchAgentByID(this)
    },
    save() {
      return DefaultAgent.save(this)
    },
    validate() {
      return DefaultAgent.validate(this)
    },
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }

      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
    toggleSidebar() {
      this.$root.$emit("bv::toggle::collapse", this.uuid);
    },
  },
};
</script>

<style lang="scss" scoped>

.sidebar-container{
  max-height: 97vh;
  overflow-y: auto !important;
}

.sidebar-fixed-header{
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.sidebar-content{
  position: relative !important;
  padding-bottom: 20px;
  margin-bottom: 70px !important;
}


</style>